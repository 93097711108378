import React, { useContext, useRef, useState } from "react";
import { GeneralSettingsContext } from "../../contexts/GeneralSettingsContext";
import $axios from "../../axios";
import { getSettingValueByKey } from "../../utils/settingsUtils";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "../../contexts/CustomerAuthContext";


const Login = () => {
  const form = useRef();
  const settings = useContext(GeneralSettingsContext);
  const [formData, setFormData] = useState({});
  const { setUser } = useAuth();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  if (!settings) {
    return null;
  }

  const submitLogin = async (e) => {
    e.preventDefault();
    try{

      const response = await $axios.post('/customer/login',formData);
     
      if (response.status === 200) {
        toast.success('Login successful! Redirecting you to your dashboard.');
        setTimeout(()=>{
            setUser(response.data);
            window.location.href = '/customer-dashboard';
        },3000);
      }else{
        toast.error("Massage not sent.");
      }
    }catch (error) {
      toast.error("Massage not sent.");
      console.error('Error contact:', error);
    }
  };
  return (
    <>
      {/* ================= Contact Main start =================*/}
      <Toaster position='bottom-center' reverseOrder={false} />
      <div className='contact-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='contact-page-inner bg-gray'>
            <div className='section-title mb-4 pb-2'>
              <h2 className='title'>Welcome Back! Please Log In to Continue</h2>
              <p className='content mb-0'>Secure Access to Your Account</p>
            </div>
            <form ref={form} onSubmit={submitLogin}>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='single-input-inner'>
                    <input
                      id='email'
                      name='email'
                      type='email'
                      placeholder='Enter Your Email.'
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='single-input-inner'>
                    <input
                      id='password'
                      name='password'
                      type='password'
                      placeholder='Enter Your Password.'
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='col-12 text-center'>
                  <button
                    className='btn btn-base border-radius-5'
                    type='submit'
                  >
                    Login
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </>
  );
};

export default Login;
