
import React, { useContext, useEffect, useState } from "react";
import $axios from "../../../axios";
import ReactPaginate from "react-paginate";
import { GeneralSettingsContext } from "../../../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../../../utils/settingsUtils";


const CustomerOrderList = () => {

    const settings = useContext(GeneralSettingsContext);
    const [orders, setOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    useEffect(()=>{
        fetchData();
    },[currentPage]);

    const fetchData = async () => {
        try {
          const response = await $axios.get(`/customer/order-history?page=${currentPage + 1}`);
          setOrders(response.data);
          setPageCount(response.data.last_page);
        } catch (error) {
          console.error("Error fetching :", error);
        }
      };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const StatusBadge = ({ status }) => {
        let className = "";
        let text = "";
        
        if (status === 1) {
            className = "badge badge-success";
            text = "Complete";
        } else if (status === -1) {
            className = "badge badge-danger";
            text = "Incomplete";
        } else if (status === 0) {
            className = "badge badge-warning";
            text = "Pending";
        } else if (status === 2) {
            className = "badge badge-primary";
            text = "On Delivery";
        } else {
            return null; // If the status does not match any condition, render nothing
        }
    
        return <span className={className}>{text}</span>;
    };
    
    if (!settings) {
        return null;
    }

  return (
    <>
     <div class="card mt-4">
        <div class="card-header d-flex flex-wrap align-items-center justify-content-between">
        <h5 class="fw-medium">Recent Purchase</h5>
        <div>
            <a href="#0" class="site-color fs-14px">View All</a>
        </div>
        </div>
        <div class="card-body p-0 table-responsive">
        <table class="table site-table">
            <thead>
            <tr>
                <th>ID</th>
                <th>Order Date</th>
                <th>Shipment Date</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {orders.data && orders.data.map((data, index) => (
                <tr key={index}>
                    <td>{data.invoice_id}</td>
                    <td data-caption="Date">
                    <b>{data.order_date}</b>
                    </td>
                    <td data-caption="Date">
                    <b>{data.shipment_date ?? 'N/A'}</b>
                    </td>
                    <td data-caption="Amount">
                    <b>{data.total} {getSettingValueByKey(settings,'currency')}</b>
                    {data.discount > 0 && (
                        <p>-{data.discount}</p>
                    )} 
                    </td>
                    <td data-caption="Status">
                    <StatusBadge status={data.status} />
                    </td>
                    <td data-caption="Action">
                    <a href="#0" class="icon-btn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M251 123.13c-.37-.81-9.13-20.26-28.48-39.61C196.63 57.67 164 44 128 44S59.37 57.67 33.51 83.52C14.16 102.87 5.4 122.32 5 123.13a12.08 12.08 0 0 0 0 9.75c.37.82 9.13 20.26 28.49 39.61C59.37 198.34 92 212 128 212s68.63-13.66 94.48-39.51c19.36-19.35 28.12-38.79 28.49-39.61a12.08 12.08 0 0 0 .03-9.75m-46.06 33C183.47 177.27 157.59 188 128 188s-55.47-10.73-76.91-31.88A130.4 130.4 0 0 1 29.52 128a130.5 130.5 0 0 1 21.57-28.11C72.54 78.73 98.41 68 128 68s55.46 10.73 76.91 31.89A130.4 130.4 0 0 1 226.48 128a130.5 130.5 0 0 1-21.57 28.12ZM128 84a44 44 0 1 0 44 44a44.05 44.05 0 0 0-44-44m0 64a20 20 0 1 1 20-20a20 20 0 0 1-20 20"/></svg>
                    </a>
                    </td>
                </tr>
             ))}
            </tbody>
        </table>
        </div>
        <div className="card-footer">
            <div className="pagination justify-content-center mt-70">
                <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination justify-content-center mt-3'} // Align pagination to the right
                                activeClassName={'active'}
                                previousLinkClassName={'page-link'} // Apply Bootstrap page-link class to previous link
                                nextLinkClassName={'page-link'} // Apply Bootstrap page-link class to next link
                                pageClassName={'page-item'} // Apply Bootstrap page-item class to each page item
                                previousClassName={'page-item'} // Apply Bootstrap page-item class to previous button
                                nextClassName={'page-item'} // Apply Bootstrap page-item class to next button
                                pageLinkClassName={'page-link'} // Apply Bootstrap page-link class to each page number link
                                breakClassName={'page-item'} // Apply Bootstrap page-item class to break element
                                breakLinkClassName={'page-link'} // Apply Bootstrap page-link class to break link
                            />
            </div>
        </div>
    </div>
    </>
  );
};

export default CustomerOrderList;
