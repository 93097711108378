import React, { useContext, useRef, useState } from "react";
import CustomerCardBox from "./partials/CustomerCardBox";
import CustomerSideBar from "./partials/CustomerSidebar";
import CustomerOrderList from "./partials/CustomerOrderList";


const Dashboard = ({customer}) => {

  return (
    <>
      <div className="pd-top-120 pd-bottom-120">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-3">
                <CustomerSideBar customer={customer}/>
              </div>
              <div className="col-lg-9 ps-lg-4">
                  <CustomerCardBox />
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default Dashboard;
